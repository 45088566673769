/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: `root`,
})
export class IntercomService {
  /**
   * Shutdown intercom
   */
  shutdown(): void {
    // @ts-ignore
    window[`intercomSettings`] = {
      ...window[`intercomSettings`],
      app_id: `ea8g7nxz`,
    };
    // @ts-ignore
    window[`Intercom`](`shutdown`);
    // @ts-ignore
    window[`Intercom`](`boot`, { app_id: `ea8g7nxz` });
  }

  /**
   * Update intercom settings
   */
  updateSettings(email: string, userId: string): void {
    const user_hash = CryptoJS.HmacSHA256(
      userId,
      environment.intercom.secret
    ).toString(CryptoJS.enc.Hex);

    // @ts-ignore
    window[`intercomSettings`] = {
      ...window[`intercomSettings`],
      app_id: `ea8g7nxz`,
      user_id: userId,
      user_hash,
      email, // Email address
      created_at: new Date(), // Signup date as a Unix timestamp
    };
  }

  /**
   * Update intercom language
   *
   * @param language
   */
  updateLanguage(language: string): void {
    window[`intercomSettings`] = {
      ...window[`intercomSettings`],
      language_override: language,
    };

    window[`Intercom`](`boot`, window[`intercomSettings`]);
  }

  /**
   * Update intercom analysis count
   *
   * @param analysisCount
   */
  updateAnalysisCount(analysisCount: number): void {
    window[`intercomSettings`] = {
      ...window[`intercomSettings`],
      analysis_count: analysisCount,
    };
  }

  openArticle(articleId: number): void {
    window[`Intercom`](`showArticle`, articleId);
  }

  openIntercomChat(): void {
    window[`Intercom`](`showNewMessage`);
  }
}
